import React from "react";
function CagnottePlus() {
    const imageUrl = process.env.PUBLIC_URL

    return(

        <div className="banner">
        <div className="banner__overlay">
            <div className="container">
                <div className="row">
                    <div className="col-12 text-center text-lg-start">
                        <h1 className="global__title global__title-dark text-capitalize">Les des Cagnottes</h1>
                        <ul className="banner__ul">
                            <li className="banner__ul-list p-0">
                                <a className="banner__ul-link" href="index-2.html">
                                    home
                                </a>
                            </li>
                            <li className="banner__ul-list">
                                Cagnotte Listes
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="banner__element d-none d-lg-block">
                    <img src={`${imageUrl}/assets/img/element-3.svg`} alt="image"/>
                </div>
                <div className="banner__polygon d-none d-lg-block">
                    <img src={`${imageUrl}/assets/img/polygon.svg`} alt="image"/>
                </div>
            </div>
        </div>
    </div>
    )
    
};
export default CagnottePlus;