import React from "react";
import './css/Education.css'
function Educations() {
    return(
        <>
        <div className="education global__py pt-0">
        <div className="container p-sm-0">
            <div className="row">
                <div className="col-12 col-lg-8">
                    <div className="col-md-10">
                        <h2 className="global__heading mb-0">Ensure Education for every poor children</h2>
                        <p className="education__price global__desc"><span className="education__price-show">$18,610</span> of
                            $10,000 raised
                        </p>
                    </div>
                    <div className="">
                        <div className="card education__card">
                            <div className="education__card-img">
                                <img className="img-fluid w-100" src="assets/img/single-case.png" alt="image"/>
                                <h4 className="education__card-tag">Education</h4>
                            </div>
                            <div className="">
                                <p className="education__para education__para-mt">
                                    On the other hand, we denounce with righteous indignation and dislike men who are so
                                    beguiled and demoralized by the
                                    charms of pleasure of the moment, so blinded by desire, that they cannot foresee the
                                    pain and trouble that are bound to
                                    ensue and equal blame belongs to those who fail in their duty through weakness of
                                    will, which is the same as saying
                                    through shrinking from toil and pain.
                                </p>
                                <p className="education__para my-3">
                                    These cases are perfectly simple and easy to distinguish. In a free hour, when our
                                    power of choice is untrammelled and
                                    when nothing prevents our being able to do what we like best, every pleasure is to
                                    be welcomed and every pain avoided.
                                </p>
                                <p className="education__para">
                                    But in certain circumstances and owing to the claims of duty or the obligations of
                                    business it will frequently occur
                                    that pleasures have to be repudiated and annoyances accepted. The wise man therefore
                                    always holds in these matters to
                                    this principle of selection: he rejects pleasures.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="">
                        <h3 className="education__headline">We want to ensure the education for the kids.</h3>
                        <p className="education__para education__para-mb">
                            These cases are perfectly simple and easy to distinguish. In a free hour, when our power of
                            choice is untrammelled and
                            when nothing prevents our being able to do what we like best, every pleasure.
                        </p>
                        <div className="">
                            <ul className="education__list">
                                <li className="education__para">
                                    The wise man therefore always holds in these matters.
                                </li>
                                <li className="education__para">
                                    In a free hour, when our power of choice and when nothing.
                                </li>
                                <li className="education__para">
                                    Else he endures pains to avoid worse pains.
                                </li>
                                <li className="education__para">
                                    We denounce with righteous indignation and dislike men.
                                </li>
                                <li className="education__para">
                                    Which is the same as saying through.
                                </li>
                            </ul>
                        </div>
                        <p className="education__para">
                            But in certain circumstances and owing to the claims of duty or the obligations of business
                            it will frequently occur
                            that pleasures have to be repudiated and annoyances accepted. The wise man therefore always
                            holds in these matters to
                            this principle of selection: he rejects pleasures.
                        </p>
                    </div>
                    <div className="">
                        <ul className="education__cases-list">
                            <li>
                                <a className="education__cases-list--img" href="single-cases.html">
                                    <img className="" src="assets/img/recent-img-1.png" alt="image"/>
                                </a>
                                <div className="">
                                    <a href="single-cases.html" className="education__cases-list--link">Education
                                        program in
                                        Uganda</a>
                                    <p className="education__cases-list--date">October 25, 2023</p>
                                </div>
                            </li>
                            <li>
                                <a className="education__cases-list--img" href="single-cases.html">
                                    <img className="" src="assets/img/recent-img-2.png" alt="image"/>
                                </a>
                                <div className="">
                                    <a href="single-cases.html" className="education__cases-list--link">African water
                                        crisis :
                                        Children
                                        and women</a>
                                    <p className="education__cases-list--date">October 25, 2023</p>
                                </div>
                            </li>
                            <li>
                                <a className="education__cases-list--img" href="single-cases.html">
                                    <img className="" src="assets/img/recent-img-3.png" alt="image"/>
                                </a>
                                <div className="">
                                    <a href="single-cases.html" className="education__cases-list--link">Be kind for the
                                        poor
                                        people and
                                        the kids.</a>
                                    <p className="education__cases-list--date">October 25, 2023</p>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                
                <div className="col-12 col-sm-8 col-lg-4 mt-5 mt-lg-0">
                    <div className="education__search">
                        <h4 className="education__txt">search</h4>
                        <form action="#" method="POST" className="d-flex position-relative mt-3">
                            <input className="education__inp form-control" type="text" placeholder="Enter your email"/>
                            <button className="global__btn education__inp-btn"><i
                                    className="fa-solid fa-magnifying-glass"></i></button>
                        </form>
                    </div>
                    <div className="education__cases">
                        <h4 className="education__txt">recent cases</h4>
                        <div className="">
                            <ul className="education__cases-list">
                                <li>
                                    <a className="education__cases-list--img" href="single-cases.html">
                                        <img className="" src="assets/img/recent-img-1.png" alt="image"/>
                                    </a>
                                    <div className="">
                                        <a href="single-cases.html" className="education__cases-list--link">Education
                                            program in
                                            Uganda</a>
                                        <p className="education__cases-list--date">October 25, 2023</p>
                                    </div>
                                </li>
                                <li>
                                    <a className="education__cases-list--img" href="single-cases.html">
                                        <img className="" src="assets/img/recent-img-2.png" alt="image"/>
                                    </a>
                                    <div className="">
                                        <a href="single-cases.html" className="education__cases-list--link">African water
                                            crisis :
                                            Children
                                            and women</a>
                                        <p className="education__cases-list--date">October 25, 2023</p>
                                    </div>
                                </li>
                                <li>
                                    <a className="education__cases-list--img" href="single-cases.html">
                                        <img className="" src="assets/img/recent-img-3.png" alt="image"/>
                                    </a>
                                    <div className="">
                                        <a href="single-cases.html" className="education__cases-list--link">Be kind for the
                                            poor
                                            people and
                                            the kids.</a>
                                        <p className="education__cases-list--date">October 25, 2023</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="education__cases">
                        <h4 className="education__txt">Categories</h4>
                        <div className="">
                            <ul className="education__cases-list">
                                <li className="education__cases-list--m">
                                    <a href="#" className="education__cases-list--link education__cases-list--tag">healthy
                                        food</a>
                                </li>
                                <li className="education__cases-list--m">
                                    <a href="#" className="education__cases-list--link education__cases-list--tag">Free
                                        Education</a>
                                </li>
                                <li className="education__cases-list--m">
                                    <a href="#" className="education__cases-list--link education__cases-list--tag">Clean
                                        Water</a>
                                </li>
                                <li className="education__cases-list--m">
                                    <a href="#" className="education__cases-list--link education__cases-list--tag">Medical
                                        Facilities</a>
                                </li>
                                <li className="education__cases-list--m">
                                    <a href="#" className="education__cases-list--link education__cases-list--tag">Halping
                                        Poor</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-10 col-xl-8">
                    <form action="#" method="POST" className="form border-0">
                        <div className="d-flex align-items-center">
                            <img className="form__icon" src="assets/img/dollar-lg-S.svg" alt="icon"/>
                            <p className="form__price">$100</p>
                        </div>
                        <hr className="form__border"/>
                        <div className="form__mb">
                            <ul className="form__ul">
                                <li>
                                    <label className="form__ul-label form__ul-label--pointer">
                                        <span className="form__ul-div d-block">
                                            $25
                                            <input type="radio" name="radio"/>
                                            <span
                                                className="form__ul-label--checkmark form__ul-label--checkmark---left"></span>
                                        </span>
                                    </label>
                                </li>
                                <li>
                                    <label className="form__ul-label form__ul-label--pointer">
                                        <span className="form__ul-div d-block">
                                            $50
                                            <input type="radio" name="radio"/>
                                            <span
                                                className="form__ul-label--checkmark form__ul-label--checkmark---left"></span>
                                        </span>
                                    </label>
                                </li>
                                <li>
                                    <label className="form__ul-label form__ul-label--pointer">
                                        <span className="form__ul-div d-block">
                                            $100
                                            <input type="radio" name="radio"/>
                                            <span
                                                className="form__ul-label--checkmark form__ul-label--checkmark---left"></span>
                                        </span>
                                    </label>
                                </li>
                                <li>
                                    <label className="form__ul-label form__ul-label--pointer">
                                        <span className="form__ul-div d-block">
                                            $250
                                            <input type="radio" name="radio"/>
                                            <span
                                                className="form__ul-label--checkmark form__ul-label--checkmark---left"></span>
                                        </span>
                                    </label>
                                </li>
                                <li>
                                    <div data-bs-toggle="collapse" data-bs-target="#collapseExample"
                                        aria-controls="collapseExample">
                                        <label className="form__ul-label form__ul-label--pointer">
                                            <span className="form__ul-div d-block">
                                                Custom Amount
                                                <input type="radio" name="radio"/>
                                                <span className="form__ul-label--checkmark"></span>
                                            </span>
                                        </label>
                                    </div>
                                </li>
                            </ul>
                            <div className="collapse col-5 col-sm-3 col-lg-2 mt-3" id="collapseExample">
                                <input className="form-control form__input" type="number" placeholder="$50"/>
                            </div>
                        </div>
                        <div className="form__mb">
                            <ul className="form__ul">
                                <li className="bg-transparent py-0 ps-0">
                                    <label className="form__ul-label form__ul-label--pointer">
                                        <span className="form__ul-div d-block px-0">
                                            <input type="radio" id="testFive" name="check"/>
                                            <span className="form__ul-label--checkmark" style={{left: '0;'}}></span>
                                            Test Donation
                                        </span>
                                    </label>
                                </li>
                                <li className="bg-transparent py-0 ps-0">
                                    <label className="form__ul-label form__ul-label--pointer">
                                        <span className="form__ul-div d-block px-0">
                                            <input type="radio" id="testSix" name="check"/>
                                            <span className="form__ul-label--checkmark" style={{left: '0;'}}></span>
                                            Offline Donation
                                        </span>
                                    </label>
                                </li>
                            </ul>
                        </div>
                        <div className="form__mb">
                            <h4 className="form__heading form__mb">Personal Information</h4>
                            <div className="d-sm-flex col-12">
                                <div className="col-sm-6 pe-sm-3">
                                    <label className="form__ul-label form__ul-label--lg" for="first">first name
                                        <span className="text-danger ps-1">*</span>
                                        <span className="ps-2"><img src="assets/img/question.svg" alt="image"/></span>
                                    </label>
                                    <input className="form-control form__input" id="first" type="text"
                                        placeholder="First Name"/>
                                </div>
                                <div className="col-sm-6 ps-sm-3 mt-4 mt-sm-0">
                                    <label className="form__ul-label form__ul-label--lg" for="last">last name<span
                                            className="text-danger ps-1">*</span><span className="ps-2"><img
                                                src="assets/img/question.svg" alt="image"/></span></label>
                                    <input className="form-control form__input" id="last" type="text"
                                        placeholder="Last Name"/>
                                </div>
                            </div>
                            <div className="col-12 mt-4">
                                <div className="">
                                    <label className="form__ul-label form__ul-label--lg" for="email">Email Address
                                        <span className="text-danger ps-1">*</span><span className="ps-2"><img
                                                src="assets/img/question.svg" alt="image"/></span></label>
                                    <input className="form-control form__input" id="email" type="email" placeholder="Email"/>
                                </div>
                                <div className="mt-4">
                                    <label className="form__ul-label form__ul-label--lg ps-0" for="comment">Comment
                                        <span className="ps-2"><img src="assets/img/question.svg"
                                                alt="image"/></span></label>
                                    <textarea className="form-control form__input" id="comment" cols="30" rows="5"
                                        placeholder="Leave a Comment"></textarea>
                                </div>
                                <div className="mt-4 d-flex align-items-center">
                                    <label className="form__ul-label form__ul-label--pointer ps-0">
                                        <span className="form__ul-div d-block">
                                            <input type="checkbox" id="testSaven" name="check"/>
                                            <span className="form__ul-label--checkmark form__ul-label--checkmark2"></span>
                                            <span className="ps-2">Make
                                                this an
                                                anonymous
                                                donation.</span>
                                            <span className="ps-2">
                                                <img src="assets/img/question.svg" alt="image"/>
                                            </span>
                                        </span>
                                    </label>
                                </div>
                                <div className="">
                                    <button className="btn donation__btn global__btn" data-bs-toggle="modal"
                                        data-bs-target="#exampleModal" type="button">donation now</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>

    <div className="modal fade" id="exampleModal" tabindex="-1" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered form__dialog">
            <div className="modal-content border-0 bg-transparent">
                <form action="#" method="POST" className="form global__white form__pd mt-0">
                    <div className="">
                        <div className="">
                            <button className="btn form__return modal__right-close" data-bs-dismiss="modal" type="button">
                                <i className="fa-solid fa-chevron-left"></i>
                                Return to fundraiser
                            </button>
                        </div>
                        <div className="d-sm-flex align-items-center form__my">
                            <img className="img-fluid form__img" src="assets/img/form-img.png" alt="image"/>
                            <div className="mt-4 mt-sm-0">
                                <h4 className="form__small">You're supporting <a href="#"
                                        className="form__small-bold">Essentials
                                        Fund</a>
                                </h4>
                                <h4 className="form__small mb-0">Your donation will benefit <a href="#"
                                        className="form__small-bold">
                                        Fundz.org
                                    </a> </h4>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex align-items-center">
                        <div className="form__icon" style={{background: '#F2F8F7;'}}>
                            <img className="" src="assets/img/dollar.svg" alt="image"/>
                        </div>
                        <p className="form__price">$100</p>
                    </div>
                    <hr className="form__border"/>
                    <div className="form__mb">
                        <ul className="form__ul">
                            <li className="form__dark">
                                <label className="form__ul-label form__ul-label--pointer">
                                    <span className="form__ul-div d-block">
                                        $25
                                        <input type="radio" name="price"/>
                                        <span className="form__ul-label--checkmark form__ul-label--checkmark---left"></span>
                                    </span>
                                </label>
                            </li>
                            <li className="form__dark">
                                <label className="form__ul-label form__ul-label--pointer">
                                    <span className="form__ul-div d-block">
                                        $50
                                        <input type="radio" name="price"/>
                                        <span className="form__ul-label--checkmark form__ul-label--checkmark---left"></span>
                                    </span>
                                </label>
                            </li>
                            <li className="form__dark">
                                <label className="form__ul-label form__ul-label--pointer">
                                    <span className="form__ul-div d-block">
                                        $100
                                        <input type="radio" name="price"/>
                                        <span className="form__ul-label--checkmark form__ul-label--checkmark---left"></span>
                                    </span>
                                </label>
                            </li>
                            <li className="form__dark">
                                <label className="form__ul-label form__ul-label--pointer">
                                    <span className="form__ul-div d-block">
                                        $250
                                        <input type="radio" name="price"/>
                                        <span className="form__ul-label--checkmark form__ul-label--checkmark---left"></span>
                                    </span>
                                </label>
                            </li>
                            <li className="form__dark" data-bs-toggle="collapse" data-bs-target="#collapseTwo"
                                aria-expanded="false" aria-controls="collapseTwo">
                                <label className="form__ul-label form__ul-label--pointer">
                                    <span className="form__ul-div d-block">
                                        Custom Amount
                                        <input type="radio" name="price"/>
                                        <span className="form__ul-label--checkmark"></span>
                                    </span>
                                </label>
                            </li>
                        </ul>
                        <div className="collapse col-5 col-sm-3 col-lg-2  mt-3" id="collapseTwo">
                            <input className="form-control form__input" type="number" placeholder="$50"/>
                        </div>
                    </div>
                    <div className="">
                        <h4 className="form__heading form__mb">Payment method</h4>
                        <div className="col-12">
                            <div className="accordion form__heading-accordion" id="accordionExample">
                                <div className="accordion-item form__heading-accordion--item">
                                    <div className="accordion-heade form__heading-accordion--header d-sm-flex align-items-center justify-content-between pb-3 ps-2 ps-sm-0 pb-sm-0"
                                        id="headingOne" data-bs-toggle="collapse" data-bs-target="#collapseOne"
                                        aria-controls="collapseOne">
                                        <div className="w-100 pb-0">
                                            <label className="form__ul-label form__ul-label--pointer">
                                                <span className="form__ul-div d-block p-4 pb-0 pb-sm-4">
                                                    <i className="fa-regular fa-credit-card ms-1 me-2"></i>
                                                    Credit or debit card
                                                    <input type="radio" name="payment"/>
                                                    <span className="form__ul-label--checkmark"
                                                    style={{ left: '4%', top: '38%' }}></span>
                                                </span>
                                            </label>
                                        </div>
                                        <ul className="d-flex gap-2 mt-3 mt-sm-0">
                                            <li>
                                                <a href="#">
                                                    <img src="assets/img/p-i-1.svg" alt="image"/>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#">
                                                    <img src="assets/img/p-i-2.svg" alt="image"/>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#">
                                                    <img src="assets/img/p-i-3.svg" alt="image"/>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#">
                                                    <img src="assets/img/p-i-4.svg" alt="image"/>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div id="collapseOne" className="accordion-collapse collapse"
                                        data-bs-parent="#accordionExample">
                                        <div className="d-md-flex col-11 m-auto my-4">
                                            <div className="col-md-6">
                                                <input className="form-control form__input" type="text"
                                                    placeholder="Card number"/>
                                            </div>
                                            <div className="col-md-3 ps-md-3 mt-4 mt-md-0">
                                                <input className="form-control form__input" type="date"/>
                                            </div>
                                            <div className="col-md-3 ps-md-3 mt-4 mt-md-0">
                                                <input className="form-control form__input" type="text" placeholder="CVV"/>
                                            </div>
                                        </div>
                                        <div className="col-11 m-auto">
                                            <select className="form-select form__input">
                                                <option selected>Name on Card</option>
                                                <option value="MasterCard">MasterCard</option>
                                                <option value="VisaCard">Visa Card</option>
                                                <option value="AmericanExpress">American Express</option>
                                                <option value="Discover">Discover card
                                                </option>
                                            </select>
                                        </div>
                                        <div className="d-sm-flex col-11 m-auto mt-4">
                                            <div className="col-sm-6 pe-sm-3">
                                                <select className="form-select form__input">
                                                    <option selected>Country</option>
                                                    <option value="1">Bangladesh</option>
                                                    <option value="2">India</option>
                                                    <option value="3">Uk</option>
                                                </select>
                                            </div>
                                            <div className="col-sm-6 mt-4 mt-sm-0">
                                                <input className="form-control form__input" type="text"
                                                    placeholder="Card number"/>
                                            </div>
                                        </div>
                                        <div className="col-sm-7 col-lg-6 me-auto mt-4 ps-4">
                                            <label className="form__ul-label form__ul-label--pointer d-flex fw-normal mb-3">
                                                <span className="form__ul-div d-block">
                                                    <input type="checkbox" id="test" name="check"/>
                                                    <span
                                                        className="form__ul-label--checkmark form__ul-label--checkmark3"></span>
                                                    Save card for future donations.
                                                </span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item form__heading-accordion--item">
                                    <div className="accordion-heade form__heading-accordion--header d-sm-flex align-items-center justify-content-between pb-3 ps-2 ps-sm-0 pb-sm-0"
                                        data-bs-toggle="collapse" data-bs-target="#collapseThree"
                                        aria-controls="collapseThree" id="headingTwo">
                                        <div className="w-100 pb-0">
                                            <label className="form__ul-label form__ul-label--pointer">
                                                <span className="form__ul-div d-block p-4 pb-0 pb-sm-4">
                                                    <img className="ms-1 me-2" src="assets/img/google.svg" alt="image"/>
                                                    Google Pay
                                                    <input type="radio" name="payment"/>
                                                    <span className="form__ul-label--checkmark"
                                                        style={{left: '3.4%', top: '38%'}}></span>
                                                </span>
                                            </label>
                                        </div>
                                    </div>
                                    <div id="collapseThree" className="accordion-collapse collapse"
                                        aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                        <div className="d-md-flex col-11 m-auto my-4">
                                            <div className="col-md-6">
                                                <input className="form-control form__input" type="text"
                                                    placeholder="Card number"/>
                                            </div>
                                            <div className="col-md-3 ps-md-3 mt-4 mt-md-0">
                                                <input className="form-control form__input" type="date"/>
                                            </div>
                                            <div className="col-md-3 ps-md-3 mt-4 mt-md-0">
                                                <input className="form-control form__input" type="text" placeholder="CVV"/>
                                            </div>
                                        </div>
                                        <div className="col-11 m-auto">
                                            <select className="form-select form__input">
                                                <option selected>Name on Card</option>
                                                <option value="MasterCard">MasterCard</option>
                                                <option value="VisaCard">Visa Card</option>
                                                <option value="AmericanExpress">American Express
                                                </option>
                                                <option value="Discover">Discover card
                                                </option>
                                            </select>
                                        </div>
                                        <div className="d-sm-flex col-11 m-auto mt-4">
                                            <div className="col-sm-6 pe-sm-3">
                                                <select className="form-select form__input">
                                                    <option selected>Country</option>
                                                    <option value="1">Bangladesh</option>
                                                    <option value="2">India</option>
                                                    <option value="3">Uk</option>
                                                </select>
                                            </div>
                                            <div className="col-sm-6 mt-4 mt-sm-0">
                                                <input className="form-control form__input" type="text"
                                                    placeholder="Card number"/>
                                            </div>
                                        </div>
                                        <div className="col-sm-7 col-lg-6 me-auto mt-4 ps-4">
                                            <label className="form__ul-label form__ul-label--pointer d-flex fw-normal mb-3">
                                                <span className="form__ul-div d-block">
                                                    <input type="checkbox" id="testTwo" name="check"/>
                                                    <span
                                                        className="form__ul-label--checkmark form__ul-label--checkmark3"></span>
                                                    Save card for future donations.
                                                </span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 mt-4 form__mb">
                        <div className="mt-4">
                            <div className="col-sm-11 col-lg-8">
                                <label className="form__ul-label form__ul-label--pointer fw-normal px-2">
                                    <span className="form__ul-div d-block">
                                        <input type="checkbox" id="testEight" name="check"/>
                                        <span className="form__ul-label--checkmark form__ul-label--checkmark3 important-left"
                                            style={{ left: '0' }}></span>
                                        Don't Display my name publicly on the fundraiser.<span className="ps-2"><img
                                                src="assets/img/i.svg" alt="image"/></span>
                                    </span>
                                </label>
                            </div>
                            <div className="col-sm-10 col-lg-12">
                                <label className="form__ul-label form__ul-label--pointer fw-normal px-2">
                                    <span className="form__ul-div d-block">
                                        <input type="checkbox" id="testNine" name="check"/>
                                        <span className="form__ul-label--checkmark form__ul-label--checkmark3 important-left"
                                            style={{ left: '0' }}></span>
                                        Get occasional marketing updates from Fundz. You may unsubscribe at any
                                        time
                                    </span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 mt-4">
                        <h4 className="form__heading">Your Donation</h4>
                        <div className="mt-4">
                            <ul className="form__donation">
                                <li>
                                    Your donation
                                    <span>$100.00</span>
                                </li>
                                <li className="mb-0">
                                    Vat (%)
                                    <span>0%</span>
                                </li>
                                <li>
                                    <hr className="form__border w-100"/>
                                </li>
                                <li className="mb-0">
                                    Total due today
                                    <span>$100.00</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-md-10 col-lg-7 mx-auto d-flex justify-content-center">
                        <button className="form-control form__btn">
                            <img className="img-fluid" src="assets/img/google-lg.svg" alt="image"/>
                        </button>
                    </div>
                    <div className="col-12">
                        <h4 className="form__small">By continuing, you agree with <a href="#"
                                className="form__small-bold">GoFundMe
                                terms</a> and <a href="#" className="form__small-bold">privacy notice. </a></h4>
                    </div>
                    <hr className="form__border"/>
                    <div className="d-sm-flex align-items-start form__my mb-0">
                        <img className="img-fluid form__shield" src="assets/img/shield.svg" alt="image"/>
                        <div className="mt-3 mt-sm-0">
                            <h4 className="form__text">Fundz protects your donation</h4>
                            <p className="form__small mb-0">We guarantee you a full refund for up to a year in the
                                rare case that fraud
                                occurs. <a href="#" className="form__small-bold">See our Fundz Giving
                                    Guarantee.</a></p>
                        </div>
                    </div>

                </form>
            </div>
        </div>
    </div>
        
        </>
    )
    
};
export default Educations;