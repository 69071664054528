import React, { useEffect } from "react";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

function Globale() {
    const imageUrl = process.env.PUBLIC_URL
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        arrows: false,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 1
            }
          }
        ]
      };
    return(
        <div className="cases global__py">
        <div className="container p-sm-0">
            <div className="row">
                <div className="col-12 col-md-9 col-lg-6 text-center m-auto" data-aos="fade-down" data-aos-duration="1000">
                    <h3 className="global__text">Our cases</h3>
                    <h2 className="global__heading">
                        Popular causes what you should know
                    </h2>
                </div>
            </div>
            <Slider {...settings}>
                    <div className="card cases__card cases__card-mb">
                        <div className="cases__card-img">
                            <a href="/details">
                                <img className="img-fluid w-100" src={`${imageUrl}/assets/img/img-1.png`} alt="image"/>
                            </a>
                            <h4 className="cases__card-tag">health</h4>
                        </div>
                        <div className="card-body px-4">
                            <div className="d-flex">
                                <img className="cases__card-i" src={`${imageUrl}/assets/img/location.svg`} alt="icon"/>
                                <span className="cases__card-location ps-1">
                                    South Africa
                                </span>
                            </div>
                            <div className="">
                                <a href="/details" className="cases__card-title">Providing health food for the
                                    children</a>
                            </div>
                            <div className="cases__card-range">
                                <p className="global__desc m-0">Founded: 40.50%</p>
                                <div className="progress cases__card-progress">
                                    <div className="progress-bar cases__card-progress--bar" role="progressbar"
                                        style={{width: '40%'}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                                    </div>
                                </div>
                                <div className="cases__card-range--bottom">
                                    <div className="d-flex align-items-center cases__card-range--bottom---m gap-2">
                                        <img className="cases__card-range--dollar" src={`${imageUrl}/assets/img/box.svg`} alt="icon"/>
                                        <span className="cases__card-range--price">
                                            Rasied: $34,000
                                        </span>
                                    </div>
                                    <div className="d-flex align-items-center gap-2">
                                        <img className="cases__card-range--dollar" src={`${imageUrl}/assets/img/dollar-s.svg`}
                                            alt="icon"/>
                                        <span className="cases__card-range--price">goal: $40,500</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> 
                    <div className="card cases__card cases__card-mb">
                        <div className="cases__card-img">
                            <a href="/details">
                                <img className="img-fluid w-100" src={`${imageUrl}/assets/img/img-1.png`} alt="image"/>
                            </a>
                            <h4 className="cases__card-tag">health</h4>
                        </div>
                        <div className="card-body px-4">
                            <div className="d-flex">
                                <img className="cases__card-i" src={`${imageUrl}/assets/img/location.svg`} alt="icon"/>
                                <span className="cases__card-location ps-1">
                                    South Africa
                                </span>
                            </div>
                            <div className="">
                                <a href="/details" className="cases__card-title">Providing health food for the
                                    children</a>
                            </div>
                            <div className="cases__card-range">
                                <p className="global__desc m-0">Founded: 40.50%</p>
                                <div className="progress cases__card-progress">
                                    <div className="progress-bar cases__card-progress--bar" role="progressbar"
                                        style={{width: '40%'}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                                    </div>
                                </div>
                                <div className="cases__card-range--bottom">
                                    <div className="d-flex align-items-center cases__card-range--bottom---m gap-2">
                                        <img className="cases__card-range--dollar" src={`${imageUrl}/assets/img/box.svg`} alt="icon"/>
                                        <span className="cases__card-range--price">
                                            Rasied: $34,000
                                        </span>
                                    </div>
                                    <div className="d-flex align-items-center gap-2">
                                        <img className="cases__card-range--dollar" src={`${imageUrl}/assets/img/dollar-s.svg`}
                                            alt="icon"/>
                                        <span className="cases__card-range--price">goal: $40,500</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                       
                   
            </Slider>

        </div>
    </div>

    )
    
}

export default Globale;