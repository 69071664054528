import React from "react";

function Campanie() {
    const imageUrl = process.env.PUBLIC_URL
    return(
        <>
         <div class="org global__py pt-0">
        <div class="container p-sm-0">
            <div class="row">
                <div class="col-12 col-md-9 col-lg-7 text-center m-auto service__m" data-aos="fade-down"
                    data-aos-duration="1000">
                    <h2 class="global__heading m-0">La mission et l'organisation de notre plate-form.
                    </h2>
                    <p class="org__desc global__desc mt-3 mb-0">Nous fournissons une plate-forme de confiance aux personnes du monde entier pour soutenir les personnes et les organisateurs.</p>
                </div>
            </div>
            <div class="row align-items-xl-center">
                <div class="col-lg-6" data-aos="fade-left" data-aos-duration="1000">
                    <ul>
                        <li class="org__li">
                            <div class="org__li-img">
                                <img class="org__li-img--icon" src={`${imageUrl}/assets/img/campain.svg`} alt=""/>
                            </div>
                            <div class="">
                                <h3 class="org__li-name">Gérez vos campagnes</h3>
                                <p class="org__desc global__desc mt-2 mb-0">Suivez le nombre de personnes qui ont participé à la pétition par semaine, mois et année.</p>
                            </div>
                        </li>
                        <li class="org__li">
                            <div class="org__li-img">
                                <img class="org__li-img--icon" src={`${imageUrl}/assets/img/donation.svg`} alt=""/>
                            </div>
                            <div class="">
                                <h3 class="org__li-name">Collecte de dons</h3>
                                <p class="org__desc global__desc mt-2 mb-0">Les propriétaires des campagnes peuvent s'organiser pour recevoir les dons des supporters.</p>
                            </div>
                        </li>
                        <li class="org__li">
                            <div class="org__li-img">
                                <img class="org__li-img--icon" src={`${imageUrl}/assets/img/money.svg`} alt=""/>
                            </div>
                            <div class="">
                                <h3 class="org__li-name">Retirez votre argent</h3>
                                <p class="org__desc global__desc mt-2 mb-0">Retirez facilement votre argent sur un compte bancaire ou Paypal avec quelques configurations.</p>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="col-lg-6 d-none d-sm-block mt-5 mt-lg-0" data-aos="fade-right" data-aos-duration="1000">
                    <img class="img-fluid" src={`${imageUrl}/assets/img/draw-img.png`} alt="image"/>
                </div>
            </div>
        </div>
    </div>
        </>
    )
    
};
export default Campanie;