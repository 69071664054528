import React from "react";
function Login() {
    return(
        <>
        <div className="sign mt-4 mt-sm-3 mt-lg-0">
            <div className="sign__layer">
                <div className="container-fluid p-0">
                    <div className="d-md-flex justify-content-between position-relative h-100">
                        <div className="col-11 col-sm-10 col-md-8 col-lg-5 col-xl-4 m-auto sign__position">
                            <div className="sign__form">
                                <h5 className="sign__small d-none d-lg-block">Copyright © 2023 Company Name</h5>
                                <form action="#" method="POST" className="">
                                    <h4 className="sign__form-heading">Sign In</h4>
                                    <div className="">
                                        <div className="sign__form-mb">
                                            <label className="sign__form-label" for="emailInp" id="email">Email <span
                                                    className="text-danger">*</span></label>
                                            <input className="form-control sign__form-input" id="emailInp" type="email"
                                                placeholder="Enter your email"/>
                                            <span className="sign__text sign__text-email text-danger d-none">Email must be valid</span>
                                            <span className="sign__text sign__text-email--required text-danger d-none">Email is
                                                required</span>
                                        </div>
                                        <div className="">
                                            <label className="sign__form-label" for="passwordInp">Password <span
                                                    className="text-danger">*</span></label>
                                            <input className="form-control sign__form-input" id="passwordInp"
                                                type="password" placeholder="Create a Password"/>
                                            <div className="">
                                                <span className="sign__text sign__text-pass text-danger d-none">Password must be 3 to 5
                                                    characters long</span>
                                                <span className="sign__text sign__text-pass--required text-danger d-none">Password is
                                                    required</span>
                                            </div>
                                        </div>
                                        <div className="d-flex justify-content-between align-items-center mt-3 mb-4">
                                            <label className="sign__form-rem">
                                                <input type="checkbox" id="checkbox" name="checkbox"/>
                                                <span className="sign__form-rem--checkmark sign__text"></span>
                                                Remember for 30 days
                                            </label>
                                            <div className="">
                                                <a className="sign__form-link" href="#">Forgot password</a>
                                            </div>
                                        </div>
                                        <div className="col-10 col-sm-12 m-auto">
                                            <div className="">
                                                <input className="sign__btn sign__btn-in form-control" type="submit"
                                                    value="log in"/>
                                            </div>
                                            <div className="">
                                                <button className="sign__btn sign__btn-up form-control"><img className="pe-3"
                                                        src="assets/img/google-g.svg" alt="image"/>Sign in
                                                    with
                                                    Google</button>
                                            </div>
                                        </div>
                                        <div className="">
                                            <p className="sign__text text-center">Don’t have an account? <a
                                                    className="sign__form-link" href="/inscription">Sign up</a></p>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="">
                                <img className="sign__card-element d-none d-xxl-block" src="assets/img/element-4.png"
                                    alt="image"/>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6 d-none d-lg-block">
                            <div className="sign__slider">
                                <div className="sign__slider-item">
                                    <div className="card sign__card h-100">
                                        <img className="img-fluid h-100 sign__card-img" src="assets/img/signIn.png"
                                            alt="image"/>
                                        <div className="card-img-overlay sign__card-overlay">
                                            <div className="position-relative">
                                                <h5 className="global__heading global__heading-w sign__card-title">Sign in
                                                    to
                                                    save
                                                    and
                                                    continue
                                                </h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                               
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
    
};
export default Login;