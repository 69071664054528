import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import './css/style.css';

const ServiceSection = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const imageUrl = process.env.PUBLIC_URL

  useEffect(() => {
    const handleCardClick = (page) => {
      navigate(page);
    };

    const card1 = document.getElementById('card1');
    const card2 = document.getElementById('card2');

    card1.addEventListener('click', () => handleCardClick('/lescagnottes'));
    card2.addEventListener('click', () => handleCardClick(`/${i18n.language}/${t('header.cagnotte')}`));

    return () => {
      card1.removeEventListener('click', () => handleCardClick('/lescagnottes'));
      card2.removeEventListener('click', () => handleCardClick(`/${i18n.language}/${t('header.cagnotte')}`));
    };
  }, [navigate, t, i18n]);

  return (
    <div className="service2 service2__bg global__py">
      <div className="container p-sm-0">
        <div className="row">
          {/* Card 1 */}
          <div id="card1" className="col-md-6 pe-md-4" data-aos="fade-right" data-aos-duration="1000">
            <div className="service2__card service2__card-hover">
              <div className="global__icon bg-transparent">
                <img
                  className="img-fluid service2__card-boxhide"
                  src={`${imageUrl}/assets/img/donation.svg`}
                  alt="Donation Icon"
                  style={{ height: '64px', width: '64px' }}
                />
                <img
                  className="img-fluid service2__card-boxshow"
                  src={`${imageUrl}/assets/img/donation-2.svg`} alt="Donation"
                  style={{ height: '64px', width: '64px' }}
                />
              </div>
              <div>
                <h3 className="service2__card-heading global__heading">Faire un don</h3>
                <p className="service2__card-desc global__desc mt-3 mb-0">
                Chaque geste compte ! Votre don peut faire une réelle différence dans la vie de ceux qui 
                en ont le plus besoin. Ensemble, nous pouvons apporter de l'aide et de l'espoir là où il en est le plus nécessaire.
                </p>
              </div>
            </div>
          </div>
          {/* Card 2 */}
          <div id="card2" className="col-md-6 ps-md-4" data-aos="fade-left" data-aos-duration="1000">
            <div className="service2__card service2__card-hover mb-0">
              <div className="global__icon bg-transparent">
                <img
                  className="img-fluid service2__card-handshow"
                  src={`${imageUrl}/assets/img/hands.svg`}
                  alt="Volunteer Icon"
                  style={{ height: '64px', width: '64px' }}
                />
                <img
                  className="img-fluid service2__card-handhide"
                  src={`${imageUrl}/assets/img/hands-2.svg`}
                  alt="Volunteer Icon"
                  style={{ height: '64px', width: '64px' }}
                />
              </div>
              <div>
                <h3 className="service2__card-heading global__heading">Cree une Cagnotte</h3>
                <p className="service2__card-desc global__desc mt-3 mb-0">
                En créant une cagnotte, vous pouvez mobiliser votre famille, vos amis et votre réseau pour collecter les fonds nécessaires afin 
                de réaliser des projets ou soutenir des causes importantes.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceSection;
