import React from "react";
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

function Signup() {
    const imageUrl = process.env.PUBLIC_URL
    return(
        <div className="sign">
            <div className="container-fluid p-0">
                <div className="sign__layer">
                    <div className="d-md-flex justify-content-between position-relative h-100">
                        <div className="col-11 col-sm-10 col-md-8 col-lg-5 col-xl-4 m-auto sign__position">
                            <div className="sign__form">
                                <h5 className="sign__small d-none d-lg-block">Copyright © 2024 CagnottePlus</h5>
                                <form action="#" method="POST" className="" id="form">
                                    <h4 className="sign__form-heading">Sign up</h4>
                                    <div className="">
                                        <div className="sign__form-mb">
                                            <label className="sign__form-label" for="nameInp">name <span
                                                    className="text-danger">*</span></label>
                                            <input className="form-control sign__form-input" id="nameInp" name="name"
                                                type="text" placeholder="Enter your name"/>
                                            <div className="">
                                                <span className="sign__text sign__text-name text-danger d-none">Use a valid
                                                    name</span>
                                                <span
                                                    className="sign__text sign__text-name--required text-danger d-none">Name
                                                    is
                                                    required</span>
                                            </div>
                                        </div>
                                        <div className="sign__form-mb">
                                            <label className="sign__form-label" for="emailInp">Email <span
                                                    className="text-danger">*</span></label>
                                            <input className="form-control sign__form-input" id="emailInp" name="email"
                                                type="email" placeholder="Enter your email"/>
                                            <div className="">
                                                <span className="sign__text sign__text-email text-danger d-none">Email must
                                                    be
                                                    valid</span>
                                                <span
                                                    className="sign__text sign__text-email--required text-danger d-none">Email
                                                    is
                                                    required</span>
                                            </div>
                                        </div>
                                        <div className="">
                                            <label className="sign__form-label" for="passwordInp">Password <span
                                                    className="text-danger">*</span></label>
                                            <input className="form-control sign__form-input" id="passwordInp"
                                                name="password" type="password" placeholder="Create a Password"/>
                                        </div>
                                        <div className="mt-1 mb-4">
                                            <div className="">
                                                <span className="sign__text sign__text-pass text-danger d-none">Password
                                                    must be 3 to 5 characters long</span>
                                                <span
                                                    className="sign__text sign__text-pass--required text-danger d-none">Password
                                                    is
                                                    required</span>
                                            </div>
                                        </div>
                                        <div className="col-10 col-sm-12 m-auto">
                                            <div className="">
                                                <input className="sign__btn sign__btn-in form-control" type="submit"
                                                    value="Get started"/>
                                            </div>
                                            <div className="">
                                                <button className="sign__btn sign__btn-up form-control"><img className="pe-3"
                                                        src={`${imageUrl}/assets/img/google-g.svg`} alt="image"/>Sign in with
                                                    Google</button>
                                            </div>
                                        </div>
                                        <div className="">
                                            <p className="sign__text text-center">Already have an account? <a
                                                    className="sign__form-link" href="singin.html">Log in</a></p>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="">
                                <img className="sign__card-element d-none d-xxl-block" src={`${imageUrl}/assets/img/element-4.png`}
                                    alt="image"/>
                            </div>
                        </div>
                            <div className="col-12 col-lg-6 d-none d-lg-block">
                            <div className="">
                                <div className="sign__slider">
                                    <div className="sign__slider-item">
                                        <div className="card sign__card h-100">
                                            <img className="img-fluid h-100 sign__card-img" src={`${imageUrl}/assets/img/signIn.png`}
                                                alt="image"/>
                                            <img className="sign__card-element d-none d-lg-block"
                                                src={`${imageUrl}/assets/img/element-4.png`} alt="image"/>
                                            <div className="card-img-overlay sign__card-overlay">
                                                <div className="position-relative">
                                                    <h5 className="global__heading global__heading-w sign__card-title">Sign
                                                        up
                                                        for a charity account
                                                    </h5>
                                                    <p className="sign__card-p">Create an account to save and continue.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
    
};
export default Signup;