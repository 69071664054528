import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useParams, Navigate } from 'react-router-dom';
import Home from './Pages/Home';
import Nav from './Pages/Navbar';
import ServiceSection from './Pages/Services';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Display from './Pages/Display';
import Section from './Pages/components/Section';
import Globale from './Pages/components/globale';
import Testmoniale from './Pages/components/testmoniale';
import Team from './Pages/components/team';
import Join from './Pages/components/join';
import Signup from './Pages/Signup';
import CagnottePlus from './Pages/Cagnotte';
import Cagnottelist from './Pages/components/cagnottelist';
import Login from './Pages/Login';
import Details from './Pages/Cagnottedetails';
import Educations from './Pages/Educations';
import Contact from './Pages/Contact';
import CreatCagnote from './Pages/CreateCagnotte';
import i18n from './i18n';
import { I18nextProvider, useTranslation, initReactI18next } from 'react-i18next';
import Campanie from './Pages/components/campanie';
import Customers from './Pages/Customers';

function App() {
  useEffect(() => {
    AOS.init({
      offset: 120,
      delay: 0,
      duration: 400,
      easing: 'ease',
      disable: false,
      once: false,
      startEvent: 'DOMContentLoaded',
      throttleDelay: 99,
      debounceDelay: 50,
      disableMutationObserver: false,
    });

    async function detectLanguage() {
      try {
        const response = await fetch('https://ipinfo.io/json');
        const data = await response.json();
        const countryCode = data.country;
        let language = 'fr';

        switch (countryCode) {
          case 'FR':
            language = 'fr';
            break;
          case 'BJ':
            language = 'bj';
            break;
          case 'NL':
            language = 'nl';
            break;
          case 'DE':
            language = 'de';
            break;
          case 'GR':
            language = 'gr';
            break;
          case 'IT':
            language = 'it';
            break;
          case 'ES':
            language = 'es';
            break;
          case 'PT':
            language = 'pt';
            break;
          case 'RO':
            language = 'ro';
            break;
          case 'SK':
            language = 'sk';
            break;
          case 'TR':
            language = 'tr';
            break;
          default:
            language = 'fr';
        }

        i18n.changeLanguage(language);
      } catch (error) {
        console.error('Erreur lors de la détection de la langue :', error);
      }
    }

    detectLanguage();
  }, []);
  const { t } = useTranslation();

  return (
    <I18nextProvider i18n={i18n}>
      <Router>
        <Routes>
          <Route
            path="/:lng/home"
            element={
              <Nav>
                <Home />
                <ServiceSection />
                <Display />
                <Campanie/>
                <Section />
                <Globale />
                <Testmoniale />
                <Team />
                <Join />
              </Nav>
            }
          />
          <Route path="/:lng/inscription" element={<Signup />} />
          <Route path={`/:lng/${t('header.cagnotte')}`} element={<Nav><CreatCagnote /></Nav>} />
          <Route path="/:lng/lescagnottes" element={<Nav><CagnottePlus /><Cagnottelist /></Nav>} />
          <Route path="/:lng/login" element={<Nav><Login /></Nav>} />
          <Route path="/:lng/details" element={<Nav><Details /><Educations /></Nav>} />
          <Route path="/:lng/contact" element={<Nav><Contact /></Nav>} />
          <Route path="/:lng/admin" element={<Nav><Customers/></Nav>} />

          <Route
            path="/"
            element={<Navigate to="/wellcome/home" />}
          />
        </Routes>
      </Router>
    </I18nextProvider>
  );
}

export default App;
