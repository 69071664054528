import React from "react";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { FaStar } from 'react-icons/fa';




function Testmoniale() {
    function StarRating({ rating }) {
        const stars = [];
        for (let i = 0; i < 5; i++) {
          stars.push(
            <FaStar
              key={i}
              color={i < rating ? '#ffc107' : '#e4e5e9'}
              size={20}
            />
          );
        }
        return <div>{stars}</div>;
      }
      
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: false,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 1
            }
          }
        ]
      };
    return(
        <div className="testimonial">
        <div className="">
            <div className="row">
                <div className="col-12 col-md-9 col-lg-6 text-center m-auto" data-aos="fade-down" data-aos-duration="1000">
                    <h3 className="global__text">Testimonials</h3>
                    <h2 className="global__heading">What people say's about us
                    </h2>
                </div>
            </div>
      
            <div className="testimonial__body">
            <Slider {...settings}>
                    <div className="">
                        <div className="testimonial__card">
                            <div className="">
                                <ul className="testimonial__card-ul">
                                    <li><StarRating rating={4} /></li>
                                </ul>
                            </div>

                            <div className="">
                                <p className="global__desc testimonial__card-desc">Campoal is great for people to
                                    bring
                                    changes to what
                                    they believe in, it’s nice to see
                                    some good morals and common sense
                                    being acknowledge where modern governments etc fail.</p>
                                <div className="d-flex align-items-center">
                                    <img className="img-fluid" src="assets/img/client-1.png" alt="user"/>
                                    <div className="ps-3">
                                        <h4 className="testimonial__card-name">Priscilla Lucas</h4>
                                        <span className="testimonial__card-type">
                                            Founder
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="">
                        <div className="testimonial__card">
                            <div className="">
                                <ul className="testimonial__card-ul">
                                    <li><StarRating rating={5} /></li>
                                </ul>
                            </div>

                            <div className="">
                                <p className="testimonial__card-desc global__desc">Campoal is great for people to
                                    bring
                                    changes to what
                                    they believe in, it’s nice to see
                                    some good morals and common sense
                                    being acknowledge where modern governments etc fail.</p>
                                <div className="d-flex align-items-center">
                                    <img className="img-fluid" src="assets/img/client-2.png" alt="user"/>
                                    <div className="ps-3">
                                        <h4 className="testimonial__card-name">Dianne Russell</h4>
                                        <span className="testimonial__card-type">
                                            Founder
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    
                    

                </Slider>
            </div>
        </div>
    </div>
    )
    
}
export default Testmoniale;