import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUsers, faMoneyBillAlt, faHandHoldingUsd, faWallet } from "@fortawesome/free-solid-svg-icons";
import { Container, Row, Col, Card, ProgressBar } from 'react-bootstrap';
import { Nav } from 'react-bootstrap';
import { faChartBar, faExchangeAlt, faCog, faPlus } from "@fortawesome/free-solid-svg-icons";
import { background } from "@chakra-ui/react";
function Customers() {
  const totalParticipants = 100; // Remplacez ceci par la valeur réelle du nombre total de participants
  const totalCollecte = 5000; // Remplacez ceci par la valeur réelle du montant total collecté
  const totalCagnotte = 3000; // Remplacez ceci par la valeur réelle du montant total de la cagnotte
  const soldeDisponible = 2000; // Remplacez ceci par la valeur réelle du solde disponible
  const imageUrl = process.env.PUBLIC_URL;

  const cagnottes = [
    {
      title: "Providing health food for the children",
      location: "South Africa",
      progress: 100.5,
      raised: 40500,
      goal: 40500,
      image: `${imageUrl}/assets/img/img-1.png`,
    },
    {
        title: "Providing health food for the children",
        location: "South Africa",
        progress: 40.5,
        raised: 34000,
        goal: 40500,
        image: `${imageUrl}/assets/img/img-1.png`,
      },
      {
        title: "Providing health food for the children",
        location: "South Africa",
        progress: 40.5,
        raised: 34000,
        goal: 40500,
        image: `${imageUrl}/assets/img/img-1.png`,
      },
    // Ajoutez d'autres cagnottes ici si nécessaire
  ];

  return (
    <div className="container-fluid">
      <div className="row">
      <nav className="col-md-2 d-none d-md-block bg-light sidebar">
      <div className="sidebar-sticky">
      <Nav className="flex-column">
        <Nav.Item>
        <Link style={{ backgroundColor: '#198754' }} className="nav-link btn btn-light btn-sm mb-2 rounded-pill text-white" to="/dashboard">
            <FontAwesomeIcon icon={faChartBar} className="me-2 " />
            Tableau de bord
          </Link>
        </Nav.Item>
        <Nav.Item>
          <Link className="nav-link btn btn-light btn-sm mb-2 rounded-pill" to="/transactions">
            <FontAwesomeIcon icon={faExchangeAlt} className="me-2" />
            Transactions
          </Link>
        </Nav.Item>
        <Nav.Item>
          <Link className="nav-link btn btn-light btn-sm mb-2 rounded-pill" to="/transactions">
            <FontAwesomeIcon icon={faPlus} className="me-2 btn" />
            Lancer une cagnotte
          </Link>
        </Nav.Item>
        <Nav.Item>
          <Link className="nav-link btn btn-light btn-sm mb-2 rounded-pill" to="/transactions">
            <FontAwesomeIcon icon={faExchangeAlt} className="me-2" />
            Virement 
          </Link>
        </Nav.Item>
        <Nav.Item>
          <Link className="nav-link btn btn-light btn-sm mb-2 rounded-pill" to="/settings">
            <FontAwesomeIcon icon={faCog} className="me-2" />
            Paramètres
          </Link>
        </Nav.Item>
      </Nav>
    </div>
    </nav>

        <main role="main" className="col-md-9 ml-sm-auto col-lg-10 px-4">
          <div className="row">
            <div className="col-lg-3">
              <div className="card rounded-5 shadow">
                <div className="card-body">
                  <h5 className="card-title">Total Participants</h5>
                  <p className="card-text">{totalParticipants}</p>
                  <FontAwesomeIcon icon={faUsers} size="3x" />
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="card rounded-3 shadow">
                <div className="card-body">
                  <h5 className="card-title">Total Collecté</h5>
                  <p className="card-text">{totalCollecte}</p>
                  <FontAwesomeIcon icon={faMoneyBillAlt} size="3x" />
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="card rounded-3 shadow">
                <div className="card-body">
                  <h5 className="card-title">Total Cagnotte</h5>
                  <p className="card-text">{totalCagnotte}</p>
                  <FontAwesomeIcon icon={faHandHoldingUsd} size="3x" />
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="card rounded-3 shadow">
                <div className="card-body">
                  <h5 className="card-title">Solde Disponible</h5>
                  <p className="card-text">{soldeDisponible}</p>
                  <FontAwesomeIcon icon={faWallet} size="3x" />
                </div>
              </div>
            </div>
          </div>

          <Container className="py-4">
            <h3>Historique des Cagnottes</h3>
            <Row>
              {cagnottes.map((cagnotte, index) => (
                <Col key={index} lg={3} className="mb-3"><br/>
                  <Card>
                    <Card.Img variant="top" src={cagnotte.image} />
                    <Card.Body>
                      <Card.Title>{cagnotte.title}</Card.Title>
                      <div className="d-flex">
                        <img className="cases__card-i" src={`${imageUrl}/assets/img/location.svg`} alt="icon"/>
                        <span className="cases__card-location ps-1">{cagnotte.location}</span>
                      </div>
                      <Card.Text>
                        <ProgressBar now={cagnotte.progress} label={`${cagnotte.progress}%`} />
                      </Card.Text>
                      <div className="d-flex justify-content-between">
                        <div>
                          <span>Raised: ${cagnotte.raised}</span>
                        </div>
                        <div>
                          <span>Goal: ${cagnotte.goal}</span>
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              ))}
            </Row>
          </Container>

        </main>
      </div>
    </div>
  );
}

export default Customers;
