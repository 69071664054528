import React from "react";
import { Outlet, Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { FaFacebook, FaLinkedin, FaInstagram, FaTiktok, FaTimes  } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';


function Nav({ children }) {
    const { t, i18n } = useTranslation();
    return(
        <>
          <header>
          <button id="topBtn"><FontAwesomeIcon icon={faArrowUp} /></button>
        <nav className="navbar navbar-expand-lg navbar-light menu menu__scroll">
        <div className="container p-sm-0">
            <a className="navbar-brand menu__logo p-0 m-0" href="index-2.html"><img className="menu__logo-img"
                    src="http://localhost:3002/assets/img/Logo.png" alt="logo"/></a>
            <button className="navbar-toggler menu__toggle" type="button" data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
                aria-label="Toggle navigation">
                <i className="fa-solid fa-bars"></i>
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav m-auto menu__list my-3 my-lg-0">
                    <li className="nav-item dropdown">
                        <a className="nav-link dropdown-toggle menu__list-link menu__list-link--active" href={`/${i18n.language}/home`}>
                            {t('header.home')}
                        </a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link menu__list-link" href={`/${i18n.language}/about`}>about us</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link menu__list-link" href={`/${i18n.language}/lescagnottes`}>Cagnotte</a>
                    </li>
                   
                    <li className="nav-item">
                        <a className="nav-link menu__list-link" href={`/${i18n.language}/blog`}>blog</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link menu__list-link" href={`/${i18n.language}/contact`}>contact</a>
                    </li>
                </ul>
                <div className="d-sm-flex d-lg-block gap-4 mt-3 mt-lg-0">
                    <a href={`/${i18n.language}/login`} className="btn menu__btn menu__btn-in mb-3 mb-lg-0">sign in</a>
                    <a href={`/${i18n.language}/inscription`} className="btn menu__btn menu__btn-up mb-3 mb-lg-0">sign up</a>
                </div>
            </div>
        </div>
    </nav>
    </header>
        <main>{children}</main>
        <div className="footer">
        <div className="container p-sm-0">
            <div className="row justify-content-between">
                <div className="col-12 col-md-6 col-lg-4 col-xl-3">
                    <a href="index-2.html">
                        <img className="img-fluid" src="http://localhost:3002/assets/img/logo-footer.png" alt="logo"/>
                    </a>
                    <p className="global__desc footer__desc">sit amet, consectetur adipiscing elit, sed do eiusmod
                        tempor
                        incididunt ut labore et dolore sed do
                        eiusmod.</p>
                    <div className="footer__social">
                        <ul className="footer__social-ul">
                            <li>
                                <a className="footer__social-link" href="https://www.facebook.com/"><FaFacebook /></a>
                            </li>
                            <li>
                                <a className="footer__social-link" href="https://linkedin.com/"><FaLinkedin /></a>
                            </li>
                            <li>
                                <a className="footer__social-link" href="https://www.instagram.com/"><FaInstagram/></a>
                            </li>
                            <li>
                                <a className="footer__social-link" href="https://twitter.com/"><FaTiktok/></a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4 col-xl-2 d-md-flex justify-content-center my-5 my-md-0">
                    <div className="">
                        <h4 className="footer__sub">explore</h4>
                        <ul className="footer__list">
                            <li>
                                <a className="footer__list-item" href="about.html">
                                    about us
                                </a>
                            </li>
                            <li>
                                <a className="footer__list-item" href="#">
                                    pages
                                </a>
                            </li>
                            <li>
                                <a className="footer__list-item" href="blog.html">
                                    blog
                                </a>
                            </li>
                            <li>
                                <a className="footer__list-item" href="faqs.html">
                                    Privecy & Policy
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4 col-xl-2 mb-5 mb-md-0 mt-md-5 mt-lg-0">
                    <div className="">
                        <h4 className="footer__sub">Donate</h4>
                        <ul className="footer__list">
                            <li>
                                <a className="footer__list-item" href="donate.html">
                                    Give Donation
                                </a>
                            </li>
                            <li>
                                <a className="footer__list-item" href="volunteer.html">
                                    Become Volunteer
                                </a>
                            </li>
                            <li>
                                <a className="footer__list-item" href="#">
                                    Child Sponsorship
                                </a>
                            </li>
                            <li>
                                <a className="footer__list-item" href="donate.html">
                                    Other Ways to Give
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="col-12 col-md-6 col-xl-4 mt-md-5 mt-xl-0">
                    <h4 className="footer__sub">Subscribe Newsletter</h4>
                    <p className="global__desc footer__desc">Join our newsletter to stay updated about our latest news
                        and
                        articles.
                    </p>
                    <form action="#" method="POST" className="d-flex position-relative">
                        <input className="footer__inp form-control" type="text" placeholder="Enter your email"/>
                        <button className="global__btn footer__inp-btn">subscribe</button>
                    </form>
                </div>
            </div>
            <hr className="footer__border"/>
            <div className="row">
                <div className="col-12 text-center">
                    <p className="footer__bottom">Copyright © 2023 Company Name and Address here</p>
                </div>
            </div>
        </div>
    </div>

        <Outlet />

</>

    )
    
}

export default Nav;