import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Importez les styles Quill

function CreatCagnote() {
    const [formData, setFormData] = useState({
        image: '',
        title: '',
        category: '',
        limitAmount: '',
        targetAmount: '',
        isPrivate: false,
        description: ''
    });

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    const handleDescriptionChange = (value) => {
        setFormData(prevState => ({
            ...prevState,
            description: value
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(formData); // Afficher les données dans la console
    };

    const modules = {
        toolbar: [
            [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
            [{size: []}],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{'list': 'ordered'}, {'list': 'bullet'}, 
             {'indent': '-1'}, {'indent': '+1'}],
            ['link', 'image', 'video'],
            ['clean']
        ],
    };

    return (
        <>
        <div className="volunteer global__py pt-0">
            <div className="container p-sm-0">
                <div className="row">
                    <div className="col-12 col-md-9 col-lg-6 text-center m-auto">
                        <h4 className="global__heading">
                            Lancer votre cagnotte et collectez de l'argent pour toutes les occasions !
                        </h4>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-12 col-lg-6 pe-lg-4 mb-5 mb-lg-0" data-aos="fade-right" data-aos-duration="1000">
                        <form onSubmit={handleSubmit} className="form mt-0 border-0">
                            <div className="col-12 mt-4">
                                <label className="form__ul-label form__ul-label--lg" htmlFor="occupation">Sélectionnez l'image principale<span
                                        className="text-danger ps-1">*</span><span className="ps-2"><img
                                            src="http://localhost:3002/assets/img/question.svg" alt="image"/></span></label>
                                <input className="form-control form__input" id="occupation" type="file"
                                    name="image" onChange={handleChange} placeholder="Type here"/>
                            </div>
                            <div className="col-12 mt-4">
                                <label className="form__ul-label form__ul-label--lg" htmlFor="occupation">Titre de la cagnotte<span
                                        className="text-danger ps-1">*</span><span className="ps-2"><img
                                            src="http://localhost:3002/assets/img/question.svg" alt="image"/></span></label>
                                <input className="form-control form__input" id="occupation" type="text"
                                    name="title" onChange={handleChange} placeholder="Type here"/>
                                <span className="sign__text sign__text-occu--required text-danger d-none">Occupation
                                    is
                                    required</span>
                            </div>
                            <div className="col-12 mt-4">
                                <label className="form__ul-label form__ul-label--lg" htmlFor="occupation">Choisissez une catégorie<span
                                        className="text-danger ps-1">*</span><span className="ps-2"><img
                                            src="http://localhost:3002/assets/img/question.svg" alt="image"/></span></label>
                                <select className="form-control form__input" id="occupation" name="category" onChange={handleChange}>
                                    <option value="">Sélectionnez une option</option>
                                    <option value="solidarite">Solidarité</option>
                                    <option value="autre">Autre</option>
                                </select>
                                <span className="sign__text sign__text-occu--required text-danger d-none">Occupation
                                    is
                                    required</span>
                            </div>
                            <div className="d-sm-flex col-12 mt-4">
                                <div className="col-sm-6 pe-sm-3">
                                    <label className="form__ul-label form__ul-label--lg" htmlFor="emailInp">Indiquer la limit du montant
                                        <span className="text-danger ps-1">*</span>
                                        <span className="ps-2"></span>
                                    </label>
                                    <input className="form-control form__input" id="emailInp" type="text"
                                        name="limitAmount" onChange={handleChange} placeholder="10.000 €"/>
                                    <div>
                                        <span className="sign__text sign__text-email text-danger d-none">Email must
                                            be
                                            valid</span>
                                        <span className="sign__text sign__text-email--required text-danger d-none">Email
                                            is
                                            required</span>
                                    </div>
                                </div>
                                <div className="col-sm-6 ps-sm-3 mt-4 mt-sm-0">
                                    <label className="form__ul-label form__ul-label--lg" htmlFor="phnInp">Indiquer un montant à atteindre<span
                                            className="text-danger ps-1">*</span><span className="ps-2"></span></label>
                                    <input className="form-control form__input" id="phnInp" type="tel"
                                        name="targetAmount" onChange={handleChange} placeholder="50.000€"/>
                                    <div>
                                        <span className="sign__text sign__text-phn--required text-danger d-none">Phone
                                            number
                                            is
                                            required</span>
                                        <span className="sign__text sign__text-phn text-danger d-none">Number must be
                                            valid</span>
                                    </div>
                                </div>
                            </div><br/>
                            <div className="form__mb">
                                <ul className="form__ul">
                                    <li>
                                        <label className="form__ul-label form__ul-label--pointer">
                                            <span className="form__ul-div d-block">
                                                Cagnotte privée
                                                <input type="radio" name="isPrivate" onChange={handleChange}/>
                                                <span
                                                    className="form__ul-label--checkmark form__ul-label--checkmark---left"></span>
                                            </span>
                                        </label>
                                    </li>
                                </ul><br/>
                                <ul className="form__ul">
                                <li>
                                    <label className="form__ul-label form__ul-label--pointer">
                                        <span className="form__ul-div d-block">
                                        Cagnotte public
                                            <input type="radio" name="radio"/>
                                            <span
                                                className="form__ul-label--checkmark form__ul-label--checkmark---left"></span>
                                        </span>
                                    </label>
                                </li>
                            </ul>
                            <div className="collapse col-5 col-sm-3 col-lg-2 mt-3" id="collapseExample">
                                <input className="form-control form__input" type="number" placeholder="$50"/>
                            </div>
                        </div>
                            <br/>
                            <div className="form__mb">
                                <label className="form__ul-label form__ul-label--lg" htmlFor="phnInp">Description<span
                                            className="text-danger ps-1">*</span><span className="ps-2"></span></label>
                                <ReactQuill 
                                            className='form-control form__input'
                                            value={formData.description} 
                                            onChange={handleDescriptionChange} 
                                            modules={modules}
                                            formats={['header', 'font', 'size', 'bold', 'italic', 'underline', 'strike', 'blockquote', 'list', 'bullet', 'indent', 'link', 'image', 'video']}
                                            placeholder="Write about something"
                                        />

                                <div className="mb-3">
                                    <input className="donation__btn global__btn" type="submit" value="Lancer ma cagnotte" />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        </>
    );
}

export default CreatCagnote;