import React, { useEffect, useState } from "react";

function Section() {
    const [donationCount, setDonationCount] = useState(0);
    const [fundRaisedCount, setFundRaisedCount] = useState(0);
    const [volunteerCount, setVolunteerCount] = useState(0);
    const [projectCount, setProjectCount] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            // Mettre à jour le compteur de donation
            if (donationCount < 6500) {
                setDonationCount(donationCount + 10);
            }
            // Mettre à jour le compteur de fonds levés
            if (fundRaisedCount < 60) {
                setFundRaisedCount(fundRaisedCount + 1);
            }
            // Mettre à jour le compteur de volontaires
            if (volunteerCount < 250) {
                setVolunteerCount(volunteerCount + 1);
            }
            // Mettre à jour le compteur de projets
            if (projectCount < 110) {
                setProjectCount(projectCount + 1);
            }
        }, 50);

        // Nettoyer l'intervalle lors du démontage du composant
        return () => clearInterval(interval);
    }, [donationCount, fundRaisedCount, volunteerCount, projectCount]);

    return (
        <div className="num">
            <div className="container p-sm-0">
                <div className="num__body">
                    <div>
                        <h4 className="num__body-digit"><span className="num__counter">{donationCount}</span>+</h4>
                        <p className="num__body-text">Donation</p>
                    </div>
                    <div>
                        <h4 className="num__body-digit">$<span className="num__counter">{fundRaisedCount}</span>+</h4>
                        <p className="num__body-text">Found Raised</p>
                    </div>
                    <div>
                        <h4 className="num__body-digit"><span className="num__counter">{volunteerCount}</span> </h4>
                        <p className="num__body-text">Volunteer</p>
                    </div>
                    <div>
                        <h4 className="num__body-digit"><span className="num__counter">{projectCount}</span></h4>
                        <p className="num__body-text">project</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Section;
