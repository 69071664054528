import React from "react";

function Display() {
    const imageUrl = process.env.PUBLIC_URL

    return(
        <div className="donation global__py pt-0">
  <div className="container p-sm-0">
    <div className="row align-items-center justify-content-between">
      <div className="col-6 d-none d-lg-block position-relative" data-aos="fade-left" data-aos-duration="1000">
        <img className="donation__element" src={`${imageUrl}/assets/img/element.png`} alt="Image" />
        <div className="donation__img d-flex justify-content-center">
          <img className="img-fluid" src={`${imageUrl}/assets/img/add-img-2.png`}alt="Image" />
        </div>
      </div>
      <div className="col-12 col-md-9 col-lg-5" data-aos="fade-right" data-aos-duration="1000">
        <h3 className="global__text">About Us</h3>
        <h2 className="donation__heading global__heading">
          Helping each other can make the world better
        </h2>
        <p className="donation__desc global__desc mb-3">The evidence is clear. The ability to learn to read is
          the single most significant factor to allow a child to reach
          their full potential.
        </p>
        <p className="donation__desc global__desc m-0">At USA Reads, we strive to close the literacy gap and see
          kids discover a love and habit of reading. We are specifically
          focused on children from African American, Latino, ethnic minorities, and low-income backgrounds.
        </p>
        <div className="mt-5">
          <a href="donate.html" className="donation__btn global__btn">Donate Now</a>
        </div>
      </div>
    </div>
  </div>
</div>


    )
    
};
export default Display;